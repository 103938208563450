.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-auto-rows: 100px;
  gap: 10px;
  max-width: 400px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 5%;
  opacity: 0; /* Ensure items are hidden initially */
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes from-left {
  from {
      transform: translateX(-100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes from-right {
  from {
      transform: translateX(100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes from-top {
  from {
      transform: translateY(-100%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes from-bottom {
  from {
      transform: translateY(100%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

.from-left {
  animation: from-left 5s forwards;
}

.from-right {
  animation: from-right 5s forwards;
}

.from-top {
  animation: from-top 5s forwards;
}

.from-bottom {
  animation: from-bottom 5s forwards;
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
}
/* Galery.css */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}
