.countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  
  .time-box {
    text-align: center;
  }
  
  .time-unit {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(180, 169, 148, 0.5);
    border-radius: 10px;
    padding: 8px;
    margin-bottom: 5px;
  }
  
  .time-unit span {
    font-size: 2rem;
    margin: 0 2px;
  }
  
  .label {
    font-size: 0.7rem;
    color: #ffffff;
  }
  