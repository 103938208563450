.admin-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f2f2f2d4;
    overflow: auto;
}

.summary, .form-container, .list-container {
    margin-bottom: 20px;
}

.summary h3, .form-container h3, .list-container h3 {
    margin-bottom: 10px;
    color: #333;
}

.summary p {
    margin: 5px 0;
    font-size: 16px;
}

.form-container form {
    display: flex;
    flex-direction: column;
}

.form-container input {
    margin-bottom: 10px;
    padding: 8px;
    font-size: 14px;
}

.form-container button {
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.form-container button:hover {
    background-color: #0056b3;
}

.list-container table {
    width: 100%;
    border-collapse: collapse;
}

.list-container th, .list-container td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.list-container th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.list-container input {
    width: 100%;
    padding: 5px;
    font-size: 14px;
}


.btn-blue {
    padding: 5px 10px;
    background-color: #0088aa;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.btn-blue:hover {
    background-color: #1ad1ce;
}


.btn-red {
    padding: 5px 10px;
    background-color: #aa0600;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.btn-red:hover {
    background-color: #d11a1a;
}
