@font-face {
  font-family: 'Lavishly Yours';
  src: url('./fonts/LavishlyYours-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Moon Dance';
  src: url('./fonts/MoonDance-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Mouse Memoirs';
  src: url('./fonts/MouseMemoirs-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Pompiere';
  src: url('./fonts/Pompiere-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Tan Pearl';
  src: url('./fonts/tan-pearl.otf') format('opentype');
}


@font-face {
  font-family: 'Lora';
  src: url('./fonts/Lora-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Amsterdam';
  src: url('./fonts/Amsterdam\ 400.ttf') format('truetype');
}
body, html {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: 'Tan Pearl', cursive;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background: url('./img/img-main.jpg') no-repeat center center fixed;
  background-size: cover;
}

.inner-content-container{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.header-container{
  height: auto;
  width: auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  padding-top: 36px;
  padding-bottom: 36px;
}

.header-container-half{
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background-color: #333333de;
  box-sizing: border-box;
  padding-top: 36px;
  padding-bottom: 36px;
  /* background-color:wheat; */
}


.container-info {
  background: url('./img/bg-ciro.webp') no-repeat center center;
  max-width: 600px;
  margin: 0 auto;
  min-height: 100vh; 
  height: auto;
  background-size: contain;
}

.container-qr-result{
  color: #ffffff;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background: url('./img/img-main.jpg') no-repeat center center;
  flex-direction: column;
  background-size: cover;
  align-items: center;
  
}

.container-galery{
  background-color: #eeeeee;
}


.container-word {
  background-color: #eeeeee;
  background-size: cover;
  max-width: 600px;
  margin: 0 auto;
  min-height: 100vh; 
  height: auto;
  text-shadow: none;
  background-size: contain;
  color: black;
}


.container-gift {
  background: url('./img/bg-ciro.webp') no-repeat center center fixed;
  background-size: cover;
  max-width: 600px;
  margin: 0 auto;
  min-height: 100vh; 
  height: auto;
  background-size: contain;
}

@media (max-width: 600px) {
  .container-info{
    background-size: cover; 
    
  }

  .container-gift{
    background-size: cover; 
    
  }

  .container-word{
    background-size: cover; 
    
  }
}



.chevron-down {
  margin-top: 60px;
  margin-bottom: 0px;
  font-size: 2em;
  animation: bounce 3s infinite;
  cursor: pointer;
}

.font-lavis{
  font-family: 'Lavishly Yours', cursive;
  margin: 0;
  text-align: center;
}
.font-moon{
  font-family: 'Moon Dance', cursive;
  margin: 0;
  text-align: center;
}
.font-mouse{
  font-family: 'Mouse Memoirs', cursive;
  margin: 0;
  text-align: center;
}
.font-pompiere{
  font-family: 'Pompiere', cursive;
  margin: 0;
  text-align: center;
}

.font-shadow{
  text-shadow: #000000 0px 0px 10px;
  margin: 0;
}
.font-tan{
  font-family: 'Tan Pearl', cursive;
  margin: 0;
  text-align: center;
}

.font-lora{
  font-family: 'Lora', cursive;
  margin: 0;
  text-align: center;
}

.font-amsterdam{
  font-family: 'Amsterdam', cursive;
  margin: 0;
  text-align: center;
}

h1 {
  font-family: 'Amsterdam', cursive;
  font-size: 2em;
  margin: 0;
  padding: 0;
  text-align: center;
}

h2 {
  font-family: 'Moon Dance', cursive;
  font-size: 2em;
  margin: 0;
}

p {
  font-size: 1em;
  margin: 10px 0;
}
.secondary-title {
  font-family: 'Mouse Memoirs', cursive;
  font-size: 2em;
  margin: 0;
  padding: 0;
}

.chevron-down {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 2em;
  animation: bounce 3s infinite;
  cursor: pointer;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}


@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideInFromTop {
  animation: slideInFromTop 3s ease-out forwards;
}

.slideInFromBottom {
  animation: slideInFromBottom 3s ease-out forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes idleMovementLeft {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}

@keyframes idleMovementRight {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}
/* Initial state for animations */
.anim-kiri, .anim-atas, .anim-kanan,
.anim-kiri-tok, .anim-atas-tok, .anim-kanan-tok {
  opacity: 0; /* Ensure elements are hidden initially */
  transform: none; /* Reset transform property */
}

/* Applied when element is visible */
/* .animate .anim-kiri,
.animate .anim-atas,
.animate .anim-kanan, */
.animate .anim-kiri-tok,
.animate .anim-atas-tok,
.animate .anim-kanan-tok {
  opacity: 1;
  animation: none; /* Disable any animation */
}

.anim-kiri {
  animation: slideInFromLeft 3s ease-out forwards, idleMovementLeft 3s infinite;
}

.anim-atas {
  animation: slideInFromTop 3s ease-out forwards, idleMovementTop 3s infinite;
}

.anim-kanan {
  animation: slideInFromRight 3s ease-out forwards, idleMovementRight 3s infinite;
}

.animate .anim-kiri-tok {
  animation: slideInFromLeft 3s ease-out forwards;
}

.animate .anim-atas-tok {
  animation: slideInFromTop 3s ease-out forwards;
}

.animate .anim-kanan-tok {
  animation: slideInFromRight 3s ease-out forwards;
}



/* Create idleMovementTop if needed */
@keyframes idleMovementTop {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}


@keyframes backgroundTransition {
  from {
    background-size: 100% 100%;
  }
  to {
    background-size: cover;
  }
}



.btn-custom {
  font-family: "Pompiere", cursive;
  color: #fff;
  background-color: rgb(28, 27, 27);
  /* background-image: linear-gradient(to right, rgb(125, 125, 125) 0%, rgb(8, 8, 8) 100%); */
  /* box-shadow: #ffffff 0px 0px 0px 1px inset; */
  padding: 8px 16px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 16px;
  font-weight: 800;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
  cursor:pointer;
  width:"100px"
}

.btn-second{
  color: #ffffff;
  background-color: rgba(245, 240, 240, 0.217);
  box-shadow: #ffffff 0px 0px 0px 1px inset;
}


.centerin{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.card {
  border-radius: 20px;
  padding: 16px;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.69);
  background-color: rgba(180, 169, 148, 0.479);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin: 16px;
  width: 80vw; /* 80% of the viewport width */
  max-width: 300px; /* Maximum width of 300px */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
.card-qr {
  padding: 26px;
  border-radius: 20px;
  background-color: rgba(180, 169, 148, 0.479);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.69);
  color: rgb(255, 255, 255);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.black-card{
  background-color: #c7c7c778;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.702);
}

.gift-card{
  background-color: #4e4e4ea7;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.702);
}

.chiro-card{
  background-image: url('img/bg-ciro.webp');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.slideInFromRight {
  animation: slideInFromRight 3s ease-out forwards;
}

.maincolor{
  color: #ffffff;
}

.blackcolor{
  color: #000000;
}



@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Adding a class for left-to-right scroll animation */
.slideInFromLeft {
  animation: slideInFromLeft 1s ease-out forwards;
}

.round-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  animation: zoomIn 3s ease-in-out forwards;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.round-image {
  width: 150px; /* Adjust the size as needed */
  height: 150px; /* Adjust the size as needed */
  border-radius: 50%;
  object-fit: cover; /* Ensures the image covers the entire area */
  /* border: 1px solid #c9c9c9;  */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


@keyframes from-left {
  from {
      transform: translateX(-100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes from-right {
  from {
      transform: translateX(100%);
      opacity: 0;
  }
  to {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes from-top {
  from {
      transform: translateY(-100%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes from-bottom {
  from {
      transform: translateY(100%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

.from-left {
  animation: from-left 3s forwards;
}

.from-right {
  animation: from-right 3s forwards;
}

.from-top {
  animation: from-top 3s forwards;
}

.from-bottom {
  animation: from-bottom 3s forwards;
}

.fade-in{
  animation: zoomIn 5s ease-in-out forwards;
}

@keyframes fadeIn {
  to {
      opacity: 1;
  }
}

.modal-block {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 98%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  border-radius: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.music-button {
  position: absolute;
  bottom: 60px;
  right: 10px;
  width: auto;
  height: 50px;
  background: no-repeat center center;
  background-size: cover;
  cursor: pointer;
  border: none;
  transition: transform 0.3s ease;
  z-index: 999;
}

.spinning {
  animation: spin 1s linear infinite;
  transform:0;
}


.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
}

.main-content {
  display: none; /* Initially hidden */
}

.app-loaded .main-content {
  display: block; /* Show main content when loaded */
}




.circle-loader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto; /* Adjust width as needed */
}

.circle-loader .circle {
  width: 20px;
  height: 20px;
  margin: 5px;
  background-color: #333; /* Circle color */
  border-radius: 50%;
  animation: circle-animation 1.2s infinite ease-in-out;
}

.circle-loader .circle:nth-child(1) {
  animation-delay: -0.24s;
}

.circle-loader .circle:nth-child(2) {
  animation-delay: -0.12s;
}

.circle-loader .circle:nth-child(3) {
  animation-delay: 0s;
}

.circle-loader .circle:nth-child(4) {
  animation-delay: -0.12s;
}

.circle-loader .circle:nth-child(5) {
  animation-delay: -0.24s;
}

@keyframes circle-animation {
  0%, 40%, 100% {
      transform: scale(1);
  }
  20% {
      transform: scale(1.5);
  }
}