.guest-wish-container {
    width: 100%;
    max-width: 500px;
    margin: auto;
    text-align: center;
  }
  

  
  .input-pesan {
    width: 80vw;
    max-width: 550px;
    height: 100px;
    padding: 10px;
    margin: 16px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .guest-wish-container button {
    padding: 10px 20px;
    background-color: #555;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-family: 'Comic Sans MS', cursive, sans-serif;
  }
  
  .wish-list {
    margin-top: 20px;
  }

  
  .wish-item {
    background-color: rgba(180, 169, 148, 0.883);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    text-align: left;
  }

  .wish-item p{
    width: 80vw;
    max-width: 550px;
    text-align: start;
  }
  
  .wish-item h3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .wish-item p {
    color:rgb(21, 23, 24);
    margin: 5px 0 0;
    font-size: 14px;
    text-shadow: none;
  }
  