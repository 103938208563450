.copy-text-container {
    display: flex;
    align-items: center;
  }
  
  .copy-icon {
    cursor: pointer;
    margin-left: 8px;
    font-size: 1.2em;
    color: #000; /* Customize color as needed */
  }
  
  .popup {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    font-size: 0.8em;
    margin-top: 5px;
  }
  